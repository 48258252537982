import { htmlSpecialChars } from '~/modules/htmlSpecialChars';
import { isInternalLink } from '~/modules/isInternalLink';
import { isInpageLink } from '~/modules/isInpageLink';
// import {UAParser} from 'ua-parser-js';
import anime from 'animejs';
/*
 *  ページ遷移アニメーション
 * --------------------------------------------------
 */
export var pageTransition = {
    // ページ遷移後のアクション
    pageIn: function (option) {
        var wrapper = document.querySelector(option.loaderWrapper);
        if (wrapper) {
            var svgImage = wrapper.querySelector('svg');
            if (svgImage) {
                addEventListener('load', function () {
                    anime
                        .timeline({
                        autoplay: true,
                        loop: false,
                        easing: 'easeInOutCirc'
                    })
                        .add({
                        targets: '#svg0, #is-svgTraceLine, #is-svgArc',
                        opacity: 0,
                        duration: 800
                    })
                        .add({
                        targets: '#svg1 .cls-2',
                        begin: function () {
                            var el = document.querySelector('#svg1 .cls-2');
                            el.style.opacity = '1';
                        },
                        strokeDashoffset: [anime.setDashoffset, 0],
                        duration: 3000
                    }, '-=500')
                        .add({
                        targets: '#svg1 .cls-2',
                        fill: '#fff',
                        duration: 2000,
                        complete: function () {
                            document.body.classList.add(option.grantClassToBody);
                        }
                    }, '-=2000')
                        .add({
                        complete: function () {
                            wrapper.style.display = 'none';
                        }
                    });
                });
            }
        }
    },
    // ページ遷移前のアクション
    pageOut: function (option) {
        var anchors = document.querySelectorAll(option.anchors);
        anchors.forEach(function (el) {
            if (isInternalLink(el.href) === true && isInpageLink(el.href) === false) {
                el.addEventListener('click', function (event) {
                    event.preventDefault();
                    document.body.classList.add(option.grantClassToBody);
                    setTimeout(function () {
                        location = htmlSpecialChars(el.href);
                    }, 800);
                }, {
                    passive: false
                });
            }
        });
        // ブラウザバックした後、キャッシュを読んでたら発火(ios対策)
        onpageshow = function (event) {
            var classes = document.body.classList;
            if (event.persisted) {
                if (classes.contains(option.grantClassToBody)) {
                    classes.remove(option.grantClassToBody);
                }
            }
        };
    }
};
